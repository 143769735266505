import React from 'react'

const Table1 = ({columns, rows}) => {



  return (
    <>

        <div className="relative overflow-x-auto">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                <thead className="text-xs text-gray-700 uppercase bg-gray-5">
                    <tr>
                        {columns.map( (column, index) => {
                            return (
                                <th key={index} scope="col" className="px-6 py-3">
                                    {column}
                                </th>
                            )
                        } )}
                    </tr>
                </thead>
                <tbody>
                    {rows.map( (row, index) => {
                        return (
                            <tr key={index} className="bg-white border-b">
                                {row.map( (element, index) => {
                                    if (index === 0) {
                                        return (
                                            <th key={index} scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                                {element}
                                            </th>
                                        )
                                    }
                                    else {
                                        return (
                                            <td key={index} className="px-6 py-4">
                                                {element}
                                            </td>
                                        )
                                    }
                                })}
                            </tr>
                        )
                    } )}
                </tbody>
            </table>
        </div>

    </>
  )
}

export default Table1