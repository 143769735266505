import React from 'react'
import { Helmet } from 'react-helmet'

const SeoMini = ({children}) => {
  return (
    <Helmet>
        
        <title>{children} - AM</title>

    </Helmet>
  )
}

export default SeoMini