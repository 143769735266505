import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import HandleRoutes from "./components/functional/HandleRoutes"
import MiniAuthLayer from './components/layers/MiniAuthLayer'


const App = () => {
  return (
    <>

      <MiniAuthLayer>

        <BrowserRouter>
        
            <HandleRoutes />
        
        </BrowserRouter>

      </MiniAuthLayer>

    </>
  )
}

export default App