import React, { useState } from 'react'

import Table1 from '../../components/ui/Table1'

import SeoMini from "../../components/functional/SeoMini"

import { getSingleDigitNumber } from '../../components/functional/Astro'
import AstroNumberBoxSquareWithSignal from '../../components/ui/AstroNumberBoxSquareWithSignal'

const NYNameNumberCalculatorBasic = () => {

    const data = {
        seo: {
            title: "Basic - Name Number Calculator"
        },
        hero: {
            title: "Name Number Calculator",
            description: "Calculate number ruling the name, and it's significance"
        },
        table: {
            columns: ['Number', 'Astrology', 'Value', 'Original'],
        },
        mappings: {
            pythagoreanMapping: {
                "A": 1, "J": 1, "S": 1,
                "B": 2, "K": 2, "T": 2,
                "C": 3, "L": 3, "U": 3,
                "D": 4, "M": 4, "V": 4,
                "E": 5, "N": 5, "W": 5,
                "F": 6, "O": 6, "X": 6,
                "G": 7, "P": 7, "Y": 7,
                "H": 8, "Q": 8, "Z": 8,
                "I": 9, "R": 9
            },
            chaldeanMapping: {
                "A": 1, "I": 1, "J": 1, "Q": 1, "Y": 1,
                "B": 2, "K": 2, "R": 2,
                "C": 3, "G": 3, "L": 3, "S": 3,
                "D": 4, "M": 4, "T": 4,
                "E": 5, "H": 5, "N": 5, "X": 5,
                "U": 6, "V": 6, "W": 6,
                "O": 7, "Z": 7,
                "F": 8, "P": 8
            }
        }
    }

    const [tableRows, setTableRows] = useState([
        ['_AM Number', '_AM', 0, 0],
        ['Destiny Number', 'Pythagorean', 0, 0],
        ['Destiny Number', 'Chaldean', 0, 0],
        ['Soul Urge Number', 'Pythagorean', 0, 0],
        ['Soul Urge Number', 'Chaldean', 0, 0],
        ['Personality Number', 'Pythagorean',  0, 0],
        ['Personality Number', 'Chaldean',  0, 0],
    ])

    const [resultNumbers, setResultNumbers] = useState([[0,0,0], [0,0,0]])

    const handleInput = (e) => {

        const pythagoreanMapping = data.mappings.pythagoreanMapping

        const chaldeanMapping = data.mappings.chaldeanMapping

        function getFilteredNameSum(name, filter, mapping) {
            return name.toUpperCase().split('').reduce((sum, char) => {
                if (filter === "" || filter.includes(char)) {
                    return sum + (mapping[char] || 0);
                }
                return sum;
            }, 0);
        }

        function getNameSum(name, mapping) {
            return name.toUpperCase().split('').reduce((sum, char) => {
                return sum + (mapping[char] || 0);
            }, 0);
        }

        function calculateDestinyNumber(fullName) {
            const pythagoreanSum = getNameSum(fullName, pythagoreanMapping);
            const chaldeanSum = getNameSum(fullName, chaldeanMapping);
            return [
                [pythagoreanSum, getSingleDigitNumber(pythagoreanSum)],
                [chaldeanSum, getSingleDigitNumber(chaldeanSum)]
            ];
        }

        function calculateSoulUrgeNumber(fullName) {
            const vowels = "AEIOU";
            const pythagoreanSum = getFilteredNameSum(fullName, vowels, pythagoreanMapping);
            const chaldeanSum = getFilteredNameSum(fullName, vowels, chaldeanMapping);
            return [
                [pythagoreanSum, getSingleDigitNumber(pythagoreanSum)],
                [chaldeanSum, getSingleDigitNumber(chaldeanSum)]
            ];
        }

        function calculatePersonalityNumber(fullName) {
            const vowels = "AEIOU";
            const consonants = fullName.split('').filter(char => /[A-Z]/i.test(char) && !vowels.includes(char.toUpperCase())).join('');
            const pythagoreanSum = getFilteredNameSum(consonants, "", pythagoreanMapping);
            const chaldeanSum = getFilteredNameSum(consonants, "", chaldeanMapping);
            return [
                [pythagoreanSum, getSingleDigitNumber(pythagoreanSum)],
                [chaldeanSum, getSingleDigitNumber(chaldeanSum)]
            ];
        }

        const input = e.target.value;

        setTableRows([
            ['_AM Number', '_AM', calculateDestinyNumber(input)[0][1], calculateDestinyNumber(input)[0][0]],
            ['Destiny Number', 'Pythagorean', calculateDestinyNumber(input)[0][1], calculateDestinyNumber(input)[0][0]],
            ['Destiny Number', 'Chaldean', calculateDestinyNumber(input)[1][1], calculateDestinyNumber(input)[1][0]],
            ['Soul Urge Number', 'Pythagorean', calculateSoulUrgeNumber(input)[0][1], calculateSoulUrgeNumber(input)[0][0]],
            ['Soul Urge Number', 'Chaldean', calculateSoulUrgeNumber(input)[1][1], calculateSoulUrgeNumber(input)[1][0]],
            ['Personality Number', 'Pythagorean', calculatePersonalityNumber(input)[0][1], calculatePersonalityNumber(input)[0][0]],
            ['Personality Number', 'Chaldean', calculatePersonalityNumber(input)[1][1], calculatePersonalityNumber(input)[1][0]],
        ])

        setResultNumbers([
            [calculateDestinyNumber(input)[0][1], calculateSoulUrgeNumber(input)[0][1], calculatePersonalityNumber(input)[0][1]],
            [calculateDestinyNumber(input)[1][1], calculateSoulUrgeNumber(input)[1][1], calculatePersonalityNumber(input)[1][1]],
        ])

    }

  return (
    <>

        <SeoMini>{data.seo.title}</SeoMini>

        <div className="animate-fade-up animate-delay-[160ms] relative overflow-hidden">
            <div className="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 py-10 sm:py-24">
                <div className="text-center">
                <h1 className="text-4xl sm:text-6xl font-bold text-gray-800">
                    {data.hero.title}
                </h1>

                <p className="mt-3 text-gray-600">
                    {data.hero.description}
                </p>

                <div className="mt-7 sm:mt-12 mx-auto max-w-xl relative">
                    <form>
                    <div className="relative z-10 flex gap-x-3 p-3 bg-white border rounded-lg shadow-lg shadow-gray-100">
                        <div className="w-full">
                        <label htmlFor="hs-search-article-1" className="block text-sm text-gray-700 font-medium"><span className="sr-only">Search article</span></label>
                        <input onInput={handleInput} name="hs-search-article-1" id="hs-search-article-1" className="py-2.5 px-4 block w-full outline-none" placeholder="Calculate name number" autoComplete='off' autoCorrect='off' />
                        </div>
                        <div>
                        <button className="size-[46px] hidden justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none">
                            <svg className="shrink-0 size-5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="11" cy="11" r="8"/><path d="m21 21-4.3-4.3"/></svg>
                        </button>
                        </div>
                    </div>
                    </form>

                    <div className="hidden md:block absolute top-0 end-0 -translate-y-12 translate-x-20">
                    <svg className="w-16 h-auto text-orange-500" width="121" height="135" viewBox="0 0 121 135" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5 16.4754C11.7688 27.4499 21.2452 57.3224 5 89.0164" stroke="currentColor" strokeWidth="10" strokeLinecap="round"/>
                        <path d="M33.6761 112.104C44.6984 98.1239 74.2618 57.6776 83.4821 5" stroke="currentColor" strokeWidth="10" strokeLinecap="round"/>
                        <path d="M50.5525 130C68.2064 127.495 110.731 117.541 116 78.0874" stroke="currentColor" strokeWidth="10" strokeLinecap="round"/>
                    </svg>
                    </div>

                    <div className="hidden md:block absolute bottom-0 start-0 translate-y-10 -translate-x-32">
                    <svg className="w-40 h-auto text-cyan-500" width="347" height="188" viewBox="0 0 347 188" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4 82.4591C54.7956 92.8751 30.9771 162.782 68.2065 181.385C112.642 203.59 127.943 78.57 122.161 25.5053C120.504 2.2376 93.4028 -8.11128 89.7468 25.5053C85.8633 61.2125 130.186 199.678 180.982 146.248L214.898 107.02C224.322 95.4118 242.9 79.2851 258.6 107.02C274.299 134.754 299.315 125.589 309.861 117.539L343 93.4426" stroke="currentColor" strokeWidth="7" strokeLinecap="round"/>
                    </svg>
                    </div>
                </div>
                </div>
            </div>
        </div>

        <section className="animate-fade-up animate-delay-[360ms] px-5 md:px-0 max-w-4xl mx-auto">

            <div className="max-w-2xl mx-auto flex flex-col space-y-11 md:space-y-0 md:flex-row items-center justify-between px-5">
                
                <div className="flex flex-col space-y-5">
                    <div className="text-center">
                        Pythagorean
                    </div>
                    <div className="flex space-x-5">
                        <AstroNumberBoxSquareWithSignal>{resultNumbers[0][0]}</AstroNumberBoxSquareWithSignal>
                        <AstroNumberBoxSquareWithSignal>{resultNumbers[0][1]}</AstroNumberBoxSquareWithSignal>
                        <AstroNumberBoxSquareWithSignal>{resultNumbers[0][2]}</AstroNumberBoxSquareWithSignal>
                    </div>
                </div>
                
                <div className="flex flex-col space-y-5">
                    <div className="text-center">
                        Chaldean
                    </div>
                    <div className="flex space-x-5">
                        <AstroNumberBoxSquareWithSignal>{resultNumbers[1][0]}</AstroNumberBoxSquareWithSignal>
                        <AstroNumberBoxSquareWithSignal>{resultNumbers[1][1]}</AstroNumberBoxSquareWithSignal>
                        <AstroNumberBoxSquareWithSignal>{resultNumbers[1][2]}</AstroNumberBoxSquareWithSignal>
                    </div>
                </div>

            </div>

        </section>

        <section className="animate-fade-up animate-delay-[560ms] px-5 md:px-0 mt-20 max-w-4xl mx-auto">

            <Table1 rows={tableRows} columns={data.table.columns} />

        </section>

    </>
  )
}

export default NYNameNumberCalculatorBasic