import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import "preline/preline";
import RouteSet from './RouteSet';
import Navbar from '../ui/Navbar';
import Footer from '../ui/Footer';


const HandleRoutes = () => {

    const location = useLocation();

    useEffect(() => {
        window.HSStaticMethods.autoInit();
    }, [location.pathname]);

  return (
    <>

      <div id="transition"></div>

      <Navbar />

      <div className="animate-fade min-h-screen">
        <RouteSet />
      </div>

      <Footer />

    </>
  )
}

export default HandleRoutes