import React, { useEffect, useState } from 'react'

const AstroNumberBoxSquareWithSignal = ({children}) => {
    console.log(children)

    const [colorClass, setColorClass] = useState('border-gray-500 text-gray-500')

    useEffect(() => {
        switch (children) {
            case 0:
                setColorClass('border border-gray-500 text-gray-500')
                break;
            case 1:
                setColorClass('border border-green-500 text-green-500')
                break;
            case 2:
                setColorClass('border border-gray-500 text-gray-500')
                break;
            case 3:
                setColorClass('border border-gray-500 text-gray-500')
                break;
            case 4:
                setColorClass('border border-red-500 text-red-500')
                break;
            case 5:
                setColorClass('border border-gray-500 text-gray-500')
                break;
            case 6:
                setColorClass('border border-green-500 text-green-500')
                break;
            case 7:
                setColorClass('border border-red-500 text-red-500')
                break;
            case 8:
                setColorClass('border border-red-500 text-red-500')
                break;
            case 9:
                setColorClass('border border-gray-500 text-gray-500')
                break;
        
            default:
                setColorClass('border border-gray-500 text-gray-500')
                break;
        }
    }, [children])
    

  return (
    <>
     <div className={`${colorClass} rounded font-bold text-xl px-5 py-3`}>{children}</div>   
    </>
  )
}

export default AstroNumberBoxSquareWithSignal