import React from 'react'
import { Route, Routes } from 'react-router-dom'

import Dashboard from "../../pages/Dashboard"
import ToolsPage from "../../pages/ToolsPage"
import FourOhFour from "../../pages/FourOhFour"

import NYNameNumberCalculatorBasic from '../../pages/tools/NYNameNumberCalculatorBasic'

const RouteSet = () => {
  return (
    <>

      <Routes>

        <Route path='/' element={<Dashboard />} />

        {/* <Route path='/tools/' element={<ToolsPage />} /> */}
        <Route path='/tools/numerology/name-number-calculator/basic' element={<NYNameNumberCalculatorBasic />} />
        
        <Route path='*' element={<FourOhFour />} />

      </Routes>

    </>
  )
}

export default RouteSet