import React, { useEffect, useState } from 'react'

import SeoMini from "../functional/SeoMini"
import Turnstile from 'react-turnstile'

import "preline/preline";
import axios from 'axios';

const MiniAuthLayer = ({children}) => {

    const [isVerified, setIsVerified] = useState(false)
    const [isHuman, setIsHuman] = useState(false)
    const [token, setToken] = useState(false)
    const [userPin, setuserPin] = useState(false)

    useEffect(() => {
        window.HSStaticMethods.autoInit();
    }, [isHuman]);

    const getValues = () => {
        function isSixDigitNumber(str) {
            const regex = /^\d{6}$/; // Regular expression for exactly 6 digits
            return regex.test(str);
        }

        let parent = document.getElementById('_login_input_text').childNodes

        let pin = `${parent[0].value}${parent[1].value}${parent[2].value}${parent[3].value}${parent[4].value}${parent[5].value}`

        return {pin, status: isSixDigitNumber(pin)}   
    }

    const handleAuth = () => {

        axios.post(
            `https://am.api.calci.org/auth/login/${userPin.pin}`, {},
            // `http://localhost:8787/auth/login/${userPin.pin}`, {},
            {
                headers: {
                  'X-INX-HA-TOKEN': token
                }
            }            
        )
        
        .then( ({data}) => {
            if (data.status === "success") {
                setIsVerified(true)
            }
        } )
        .catch((error) => {
            if (error) {
                window.location.reload()
            }
        })

    }

    const handleInput = (e) => {

        if (e.key === 'Enter') {
            document.getElementById('_INX-auth-submit').click()
        }

        const input = document.getElementById('_login_input_text').innerText
        if (getValues().status) {
            setuserPin(getValues())
        }
        else {
            setuserPin(false)
        }
    }

    if (isVerified && isHuman) {
        return (children)
    }
    else if (isHuman) {
        return (
            <>
            

                <SeoMini>Login</SeoMini>

                <div className="w-screen h-screen fixed top-0 left-0 flex flex-col items-center justify-center">

                    <div className="-mt-20">
                        <h1 className="animate-fade-up animate-delay-[160ms] text-xl pb-11 text-center">
                            Login
                        </h1>

                        <div>

                            <div id='_login_input_text' className="flex gap-x-3" data-hs-pin-input="">
                                <input onKeyUp={handleInput} type="text" className="animate-fade-up animate-delay-[560ms] block w-[38px] text-center bg-transparent border-t-transparent border-b-2 border-x-transparent border-b-gray-200 text-sm outline-none" placeholder="⚬" data-hs-pin-input-item="1" autoFocus />
                                <input onKeyUp={handleInput} type="text" className="animate-fade-up animate-delay-[760ms] block w-[38px] text-center bg-transparent border-t-transparent border-b-2 border-x-transparent border-b-gray-200 text-sm outline-none" placeholder="⚬" data-hs-pin-input-item="2" />
                                <input onKeyUp={handleInput} type="text" className="animate-fade-up animate-delay-[960ms] block w-[38px] text-center bg-transparent border-t-transparent border-b-2 border-x-transparent border-b-gray-200 text-sm outline-none" placeholder="⚬" data-hs-pin-input-item="3" />
                                <input onKeyUp={handleInput} type="text" className="animate-fade-up animate-delay-[1160ms] block w-[38px] text-center bg-transparent border-t-transparent border-b-2 border-x-transparent border-b-gray-200 text-sm outline-none" placeholder="⚬" data-hs-pin-input-item="4" />
                                <input onKeyUp={handleInput} type="text" className="animate-fade-up animate-delay-[1360ms] block w-[38px] text-center bg-transparent border-t-transparent border-b-2 border-x-transparent border-b-gray-200 text-sm outline-none" placeholder="⚬" data-hs-pin-input-item="5" />
                                <input onKeyUp={handleInput} type="text" className="animate-fade-up animate-delay-[1560ms] block w-[38px] text-center bg-transparent border-t-transparent border-b-2 border-x-transparent border-b-gray-200 text-sm outline-none" placeholder="⚬" data-hs-pin-input-item="6" />
                                <button id='_INX-auth-submit' onClick={handleAuth} disabled={userPin ? false : true} className='ml-5 outline-none flex items-center'><span className={`duration-300 ${userPin ? 'text-gray-800' : 'text-gray-200'} animate-fade-up animate-delay-[1760ms] material-symbols-outlined`}>login</span></button>
                            </div>

                        </div>
                    </div>

                </div>

            
            </>
        )
    }
    else {
        return (
            <>
            
                <SeoMini>Human Verification</SeoMini>

                <div className="w-screen h-screen fixed top-0 left-0 flex flex-col items-center justify-center">

                    <div className="-mt-20">
                        <h1 className="animate-fade-up animate-delay-[160ms] text-xl pb-11 text-center">
                            Human Verification
                        </h1>

                        <div style={{width: '300px', height: '60px'}} className='animate-fade-up animate-delay-[560ms] rounded border'>
                            <Turnstile
                                sitekey='0x4AAAAAAAzNUkgwgsx_GzqE'
                                // sitekey='0x4AAAAAAAfXSZdL7wTywuQx'
                                theme='light'
                                onVerify={(token) => {setIsHuman(true); setToken(token)}}
                            />
                        </div>
                    </div>

                </div>
            
            </>
        )
    }

}

export default MiniAuthLayer